import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { Howl } from 'howler';
import Modal from 'react-modal';
import Svg from 'react-inlinesvg';
import animationData from '../animationData/lottie-demo.json';
import styles from '../scss/styleguide.module.scss';

import Arrow from '../images/svgs/icon_link_arrow.svg';
import Restart from '../images/svgs/icon_loop_back.svg';
import Pause from '../images/svgs/icon_pause.svg';
import Play from '../images/svgs/icon_play.svg';
import Mute from '../images/svgs/icon_mute.svg';
import Muted from '../images/svgs/icon_muted.svg';
import QuestionMark from '../images/svgs/icon_question_mark.svg';
import SpotifyIcon from '../images/svgs/icon_spotify.svg';
import SpotifyLogo from '../images/svgs/logo_spotify.svg';
import Facebook from '../images/svgs/social_facebook.svg';
import Instagram from '../images/svgs/social_instagram.svg';
import Twitter from '../images/svgs/social_twitter.svg';
import Audio from '../audio/audio-1.mp3';

export default function Styleguide() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const sound = new Howl({ src: [Audio] });

  const playSound = () => {
    sound.play();
  };

  const pauseSound = () => {
    sound.pause();
  };

  return (
    <div className={`container ${styles.styleguide}`}>
      <a className="cta cta--tangerine" href="/">
        Back
      </a>
      <h1 className="text--center">Styleguide</h1>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Colours</h2>
        <hr className="push-v--mobile--large" />
        <div className={`${styles.styleguide__colours} push-v--mobile--medium`}>
          <div className={styles.styleguide__colour}>
            <span
              className={`${styles.styleguide__circle} push-v--mobile--x-small bg--tangerine`}
            />
            <p className="push-v--mobile--xx-small">$c-tangerine</p>
            <p>#ff4632</p>
          </div>
          <div className={styles.styleguide__colour}>
            <span className={`${styles.styleguide__circle} push-v--mobile--x-small bg--green`} />
            <p className="push-v--mobile--xx-small">$c-green</p>
            <p>#c3f0c8</p>
          </div>
          <div className={styles.styleguide__colour}>
            <span className={`${styles.styleguide__circle} push-v--mobile--x-small bg--white`} />
            <p className="push-v--mobile--xx-small">$c-white</p>
            <p>#ffffff</p>
          </div>
          <div className={styles.styleguide__colour}>
            <span className={`${styles.styleguide__circle} push-v--mobile--x-small bg--black`} />
            <p className="push-v--mobile--xx-small">$c-black</p>
            <p>#191414</p>
          </div>
        </div>

        <p>To change the color of text add class: e.g. `color--tangerine`</p>
        <p>To change the background color add class: e.g. `bg--tangerine`</p>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Typography</h2>
        <hr className="push-v--mobile--large" />

        <h1 className="h1-huge push-v--mobile--small">H1</h1>
        <h1 className="push-v--mobile--small">H1 Heading</h1>
        <h1 className="h1-small-mobile push-v--mobile--small">H1 Small Mobile Heading</h1>
        <h2 className="push-v--mobile--small">H2 Heading</h2>
        <h3 className="push-v--mobile--small">H3 Heading</h3>
        <h4 className="push-v--mobile--small">H4 Heading</h4>
        <h5 className="push-v--mobile--small">H5 Heading</h5>
        <p className="text--medium push-v--mobile--small">
          text--medium body text lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
        <p className="push-v--mobile--small">
          This is the body text lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
        <p className="text--small push-v--mobile--small">
          text--small body text lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
        <p className="text--simple push-v--mobile--small">
          text--simple body text lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
        <p className="text--light push-v--mobile--small">
          text--light body text lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Buttons & CTAs</h2>
        <hr className="push-v--mobile--large" />
        <p className="push-v--mobile--small">Sizes</p>
        <div className={styles.styleguide__buttons}>
          <button type="button" className={`${styles.styleguide__button} button button--light`}>
            Primary
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--light button--large`}
          >
            Large
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark button--x-large`}
          >
            X Large
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark button--small`}
          >
            Small
          </button>
        </div>

        <p className="push-v--mobile--small">Buttons</p>
        <div className={styles.styleguide__buttons}>
          <button type="button" className={`${styles.styleguide__button} button button--dark`}>
            Dark Primary
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark button--dark--green`}
          >
            Dark Primary
          </button>
          <button type="button" className={`${styles.styleguide__button} button button--light`}>
            Light Primary
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--light button--icon`}
          >
            Light Primary Icon
            <Svg src={SpotifyIcon} />
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark button--icon`}
          >
            Dark Primary Icon
            <Svg src={Restart} />
          </button>
        </div>

        <p className="push-v--mobile--small">Disabled</p>
        <div className={styles.styleguide__buttons}>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark disabled`}
          >
            Dark Primary
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--dark button--dark--green disabled`}
          >
            Dark Primary
          </button>
          <button
            type="button"
            className={`${styles.styleguide__button} button button--light disabled`}
          >
            Light Primary
          </button>
        </div>

        <p className="push-v--mobile--x-small">CTAs</p>
        <div className={`${styles.styleguide__buttons} bg--black`}>
          <button type="button" className={`${styles.styleguide__cta} cta cta--tangerine`}>
            Choose Jamie
          </button>
          <button type="button" className={`${styles.styleguide__cta} cta cta--white`}>
            Choose Jamie
          </button>
          <button type="button" className={`${styles.styleguide__cta} cta cta--green`}>
            Choose Jamie
          </button>
        </div>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Icons</h2>
        <hr className="push-v--mobile--large" />
        <div className={styles.styleguide__icons}>
          <Svg src={SpotifyLogo} className={styles.styleguide__logo} />
          <Svg src={SpotifyIcon} className={styles.styleguide__icon} />
          <Svg src={Pause} className={styles.styleguide__icon} />
          <Svg src={Play} className={styles.styleguide__icon} />
          <Svg src={Mute} className={styles.styleguide__icon} />
          <Svg src={Muted} className={styles.styleguide__icon} />
          <Svg src={QuestionMark} className={styles.styleguide__icon} />
          <Svg src={Facebook} className={styles.styleguide__icon} />
          <Svg src={Instagram} className={styles.styleguide__icon} />
          <Svg src={Twitter} className={styles.styleguide__icon} />
          <Svg src={Arrow} className={styles.styleguide__icon} />
          <Svg src={Restart} className={styles.styleguide__icon} />
        </div>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Lottie Animation Demo</h2>
        <hr className="push-v--mobile--large" />
        <div className={styles.styleguide__lottieAnimation}>
          <Lottie options={defaultOptions} height={40} width={200} />
        </div>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Howler js Audio</h2>
        <hr className="push-v--mobile--large" />
        <Svg onClick={playSound} src={Play} className={styles.styleguide__audioIcon} />
        <Svg onClick={pauseSound} src={Pause} className={styles.styleguide__audioIcon} />
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Modal</h2>
        <hr className="push-v--mobile--large" />
        <button onClick={openModal} className="cta cta--tangerine">
          Open Modal
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Help Modal"
          className="modal"
          overlayClassName="modal__overlay"
        >
          <button onClick={closeModal} className="modal__close-cta cta cta--tangerine">
            Close
          </button>
          <div className="modal__container">
            <p className="text--thin">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic, autem debitis
              repudiandae doloremque pariatur dolorem, eos beatae alias quas saepe quisquam expedita
              quibusdam similique. Tenetur doloribus modi neque molestias. Vitae? Lorem ipsum dolor
              sit, amet consectetur adipisicing elit. Hic, autem debitis repudiandae doloremque
              pariatur dolorem, eos beatae alias quas saepe quisquam expedita quibusdam similique.
              Tenetur doloribus modi neque molestias. Vitae? Lorem ipsum dolor sit, amet consectetur
              adipisicing elit. Hic, autem debitis repudiandae doloremque pariatur dolorem, eos
              beatae alias quas saepe quisquam expedita quibusdam similique. Tenetur doloribus modi
              neque molestias. Vitae?
            </p>
          </div>
        </Modal>
      </div>

      <div className={styles.styleguide__section}>
        <h2 className="h4">Illustrations</h2>
        <hr className="push-v--mobile--large" />
      </div>
    </div>
  );
}
